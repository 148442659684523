export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyNuxtDynamic: () => import('..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyCmsComponentAdd: () => import('..\\components\\backoffice\\cms\\component-add.vue' /* webpackChunkName: "components/cms-component-add" */).then(c => c.default || c),
      LazyCmsComponentFieldEditDialog: () => import('..\\components\\backoffice\\cms\\component-field-edit-dialog.vue' /* webpackChunkName: "components/cms-component-field-edit-dialog" */).then(c => c.default || c),
      LazyCmsComponentFilterButton: () => import('..\\components\\backoffice\\cms\\component-filter-button.vue' /* webpackChunkName: "components/cms-component-filter-button" */).then(c => c.default || c),
      LazyCmsComponentTable: () => import('..\\components\\backoffice\\cms\\component-table.vue' /* webpackChunkName: "components/cms-component-table" */).then(c => c.default || c),
      LazyCmsContentAdd: () => import('..\\components\\backoffice\\cms\\content-add.vue' /* webpackChunkName: "components/cms-content-add" */).then(c => c.default || c),
      LazyCmsContentField: () => import('..\\components\\backoffice\\cms\\content-field.vue' /* webpackChunkName: "components/cms-content-field" */).then(c => c.default || c),
      LazyCmsContentsTable: () => import('..\\components\\backoffice\\cms\\contents-table.vue' /* webpackChunkName: "components/cms-contents-table" */).then(c => c.default || c),
      LazyCmsExpandingFilterButton: () => import('..\\components\\backoffice\\cms\\expanding-filter-button.vue' /* webpackChunkName: "components/cms-expanding-filter-button" */).then(c => c.default || c),
      LazyCmsFileInfo: () => import('..\\components\\backoffice\\cms\\file-info.vue' /* webpackChunkName: "components/cms-file-info" */).then(c => c.default || c),
      LazyCmsFolderAdd: () => import('..\\components\\backoffice\\cms\\folder-add.vue' /* webpackChunkName: "components/cms-folder-add" */).then(c => c.default || c),
      LazyCmsImageCropper: () => import('..\\components\\backoffice\\cms\\image-cropper.vue' /* webpackChunkName: "components/cms-image-cropper" */).then(c => c.default || c),
      LazyCmsSectionField: () => import('..\\components\\backoffice\\cms\\section-field.vue' /* webpackChunkName: "components/cms-section-field" */).then(c => c.default || c),
      LazyFinancialCreditCardDialog: () => import('..\\components\\backoffice\\financial\\credit-card-dialog.vue' /* webpackChunkName: "components/financial-credit-card-dialog" */).then(c => c.default || c),
      LazyFinancialCustomerCreditTable: () => import('..\\components\\backoffice\\financial\\customer-credit-table.vue' /* webpackChunkName: "components/financial-customer-credit-table" */).then(c => c.default || c),
      LazyFinancialInvoiceTable: () => import('..\\components\\backoffice\\financial\\invoice-table.vue' /* webpackChunkName: "components/financial-invoice-table" */).then(c => c.default || c),
      LazyFinancialPaymentDialog: () => import('..\\components\\backoffice\\financial\\payment-dialog.vue' /* webpackChunkName: "components/financial-payment-dialog" */).then(c => c.default || c),
      LazyFinancialPaymentsTable: () => import('..\\components\\backoffice\\financial\\payments-table.vue' /* webpackChunkName: "components/financial-payments-table" */).then(c => c.default || c),
      LazyConfigurationAdmColumnsAutoComplete: () => import('..\\components\\backoffice\\configuration\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/configuration-adm-columns-auto-complete" */).then(c => c.default || c),
      LazyConfigurationCustomerOrderSettings: () => import('..\\components\\backoffice\\configuration\\customer-order-settings.vue' /* webpackChunkName: "components/configuration-customer-order-settings" */).then(c => c.default || c),
      LazyConfigurationFieldRows: () => import('..\\components\\backoffice\\configuration\\field-rows.vue' /* webpackChunkName: "components/configuration-field-rows" */).then(c => c.default || c),
      LazyConfigurationFieldSettingDialog: () => import('..\\components\\backoffice\\configuration\\field-setting-dialog.vue' /* webpackChunkName: "components/configuration-field-setting-dialog" */).then(c => c.default || c),
      LazyConfigurationFieldSettings: () => import('..\\components\\backoffice\\configuration\\field-settings.vue' /* webpackChunkName: "components/configuration-field-settings" */).then(c => c.default || c),
      LazyConfigurationGlobalSettings: () => import('..\\components\\backoffice\\configuration\\global-settings.vue' /* webpackChunkName: "components/configuration-global-settings" */).then(c => c.default || c),
      LazyConfigurationGroupSettingsDialog: () => import('..\\components\\backoffice\\configuration\\group-settings-dialog.vue' /* webpackChunkName: "components/configuration-group-settings-dialog" */).then(c => c.default || c),
      LazyConfigurationModules: () => import('..\\components\\backoffice\\configuration\\modules.vue' /* webpackChunkName: "components/configuration-modules" */).then(c => c.default || c),
      LazyConfigurationRoleRows: () => import('..\\components\\backoffice\\configuration\\role-rows.vue' /* webpackChunkName: "components/configuration-role-rows" */).then(c => c.default || c),
      LazyConfigurationRoleSettingDialog: () => import('..\\components\\backoffice\\configuration\\role-setting-dialog.vue' /* webpackChunkName: "components/configuration-role-setting-dialog" */).then(c => c.default || c),
      LazyConfigurationWorkflows: () => import('..\\components\\backoffice\\configuration\\workflows.vue' /* webpackChunkName: "components/configuration-workflows" */).then(c => c.default || c),
      LazyDashboardHeader: () => import('..\\components\\backoffice\\dashboard\\dashboard-header.vue' /* webpackChunkName: "components/dashboard-header" */).then(c => c.default || c),
      LazyDashboardNewArrival: () => import('..\\components\\backoffice\\dashboard\\dashboard-new-arrival.vue' /* webpackChunkName: "components/dashboard-new-arrival" */).then(c => c.default || c),
      LazyDashboardSummaryTable: () => import('..\\components\\backoffice\\dashboard\\dashboard-summary-table.vue' /* webpackChunkName: "components/dashboard-summary-table" */).then(c => c.default || c),
      LazyDashboardTable: () => import('..\\components\\backoffice\\dashboard\\dashboard-table.vue' /* webpackChunkName: "components/dashboard-table" */).then(c => c.default || c),
      LazyDashboardMarketTable: () => import('..\\components\\backoffice\\dashboard\\market-table.vue' /* webpackChunkName: "components/dashboard-market-table" */).then(c => c.default || c),
      LazyDashboardMessagesTable: () => import('..\\components\\backoffice\\dashboard\\messages-table.vue' /* webpackChunkName: "components/dashboard-messages-table" */).then(c => c.default || c),
      LazyOrderCreationEstimateGrid: () => import('..\\components\\backoffice\\order-creation\\estimate-grid.vue' /* webpackChunkName: "components/order-creation-estimate-grid" */).then(c => c.default || c),
      LazyOrderCreationFileUploader: () => import('..\\components\\backoffice\\order-creation\\file-uploader.vue' /* webpackChunkName: "components/order-creation-file-uploader" */).then(c => c.default || c),
      LazyOrderCreationMultiselectDialog: () => import('..\\components\\backoffice\\order-creation\\multiselect-dialog.vue' /* webpackChunkName: "components/order-creation-multiselect-dialog" */).then(c => c.default || c),
      LazyMediaLibraryAddFileDialog: () => import('..\\components\\backoffice\\media-library\\add-file-dialog.vue' /* webpackChunkName: "components/media-library-add-file-dialog" */).then(c => c.default || c),
      LazyMediaLibraryAdminView: () => import('..\\components\\backoffice\\media-library\\admin-view.vue' /* webpackChunkName: "components/media-library-admin-view" */).then(c => c.default || c),
      LazyMediaLibraryChangeFolderDialog: () => import('..\\components\\backoffice\\media-library\\change-folder-dialog.vue' /* webpackChunkName: "components/media-library-change-folder-dialog" */).then(c => c.default || c),
      LazyMediaLibraryCustomerView: () => import('..\\components\\backoffice\\media-library\\customer-view.vue' /* webpackChunkName: "components/media-library-customer-view" */).then(c => c.default || c),
      LazyMediaLibraryDeleteDialog: () => import('..\\components\\backoffice\\media-library\\delete-dialog.vue' /* webpackChunkName: "components/media-library-delete-dialog" */).then(c => c.default || c),
      LazyMediaLibraryEditDialog: () => import('..\\components\\backoffice\\media-library\\edit-dialog.vue' /* webpackChunkName: "components/media-library-edit-dialog" */).then(c => c.default || c),
      LazyMediaLibraryOpenDialog: () => import('..\\components\\backoffice\\media-library\\open-dialog.vue' /* webpackChunkName: "components/media-library-open-dialog" */).then(c => c.default || c),
      LazyProductCatalogAddItemTypeDialog: () => import('..\\components\\backoffice\\product-catalog\\add-item-type-dialog.vue' /* webpackChunkName: "components/product-catalog-add-item-type-dialog" */).then(c => c.default || c),
      LazyProductCatalogCategoryDetailTable: () => import('..\\components\\backoffice\\product-catalog\\category-detail-table.vue' /* webpackChunkName: "components/product-catalog-category-detail-table" */).then(c => c.default || c),
      LazyProductCatalogCustomTablePager: () => import('..\\components\\backoffice\\product-catalog\\custom-table-pager.vue' /* webpackChunkName: "components/product-catalog-custom-table-pager" */).then(c => c.default || c),
      LazyProductCatalogFilterCard: () => import('..\\components\\backoffice\\product-catalog\\product-catalog-filter-card.vue' /* webpackChunkName: "components/product-catalog-filter-card" */).then(c => c.default || c),
      LazyProductCatalogTable: () => import('..\\components\\backoffice\\product-catalog\\product-catalog-table.vue' /* webpackChunkName: "components/product-catalog-table" */).then(c => c.default || c),
      LazyProductCategoryImageUploader: () => import('..\\components\\backoffice\\product-catalog\\product-category-image-uploader.vue' /* webpackChunkName: "components/product-category-image-uploader" */).then(c => c.default || c),
      LazyProductsProductDetail: () => import('..\\components\\backoffice\\products\\product-detail.vue' /* webpackChunkName: "components/products-product-detail" */).then(c => c.default || c),
      LazySeoCatalogTable: () => import('..\\components\\backoffice\\seo\\seo-catalog-table.vue' /* webpackChunkName: "components/seo-catalog-table" */).then(c => c.default || c),
      LazySeoDetail: () => import('..\\components\\backoffice\\seo\\seo-detail.vue' /* webpackChunkName: "components/seo-detail" */).then(c => c.default || c),
      LazySeoProductTable: () => import('..\\components\\backoffice\\seo\\seo-product-table.vue' /* webpackChunkName: "components/seo-product-table" */).then(c => c.default || c),
      LazyQuotesQuoteDetail: () => import('..\\components\\backoffice\\quotes\\quote-detail.vue' /* webpackChunkName: "components/quotes-quote-detail" */).then(c => c.default || c),
      LazyQuotesQuoteTable: () => import('..\\components\\backoffice\\quotes\\quote-table.vue' /* webpackChunkName: "components/quotes-quote-table" */).then(c => c.default || c),
      LazyShoppingCartCheckoutStepper: () => import('..\\components\\backoffice\\shopping-cart\\checkout-stepper.vue' /* webpackChunkName: "components/shopping-cart-checkout-stepper" */).then(c => c.default || c),
      LazyShoppingCartCheckout: () => import('..\\components\\backoffice\\shopping-cart\\checkout.vue' /* webpackChunkName: "components/shopping-cart-checkout" */).then(c => c.default || c),
      LazyStatusTrackingOrderCardItem: () => import('..\\components\\backoffice\\status-tracking\\order-card-item.vue' /* webpackChunkName: "components/status-tracking-order-card-item" */).then(c => c.default || c),
      LazyStatusTrackingOrderCard: () => import('..\\components\\backoffice\\status-tracking\\order-card.vue' /* webpackChunkName: "components/status-tracking-order-card" */).then(c => c.default || c),
      LazyStatusTrackingOrderDetailInfoBox: () => import('..\\components\\backoffice\\status-tracking\\order-detail-info-box.vue' /* webpackChunkName: "components/status-tracking-order-detail-info-box" */).then(c => c.default || c),
      LazyStatusTrackingOrderDetail: () => import('..\\components\\backoffice\\status-tracking\\order-detail.vue' /* webpackChunkName: "components/status-tracking-order-detail" */).then(c => c.default || c),
      LazyStatusTrackingOrderTable: () => import('..\\components\\backoffice\\status-tracking\\order-table.vue' /* webpackChunkName: "components/status-tracking-order-table" */).then(c => c.default || c),
      LazyStatusTrackingSalesOrderDetail: () => import('..\\components\\backoffice\\status-tracking\\sales-order-detail.vue' /* webpackChunkName: "components/status-tracking-sales-order-detail" */).then(c => c.default || c),
      LazyStatusTrackingSalesOrderTable: () => import('..\\components\\backoffice\\status-tracking\\sales-order-table.vue' /* webpackChunkName: "components/status-tracking-sales-order-table" */).then(c => c.default || c),
      LazyFrontendAppPage: () => import('..\\frontend\\components\\app\\page.vue' /* webpackChunkName: "components/frontend-app-page" */).then(c => c.default || c),
      LazyFrontendPageSectionsAccountMenu: () => import('..\\frontend\\components\\page-sections\\account-menu.vue' /* webpackChunkName: "components/frontend-page-sections-account-menu" */).then(c => c.default || c),
      LazyFrontendLayoutAboutUs: () => import('..\\frontend\\components\\layout\\about-us.vue' /* webpackChunkName: "components/frontend-layout-about-us" */).then(c => c.default || c),
      LazyFrontendLayoutAccountDashboard: () => import('..\\frontend\\components\\layout\\account-dashboard.vue' /* webpackChunkName: "components/frontend-layout-account-dashboard" */).then(c => c.default || c),
      LazyFrontendLayoutAccountInformation: () => import('..\\frontend\\components\\layout\\account-information.vue' /* webpackChunkName: "components/frontend-layout-account-information" */).then(c => c.default || c),
      LazyFrontendLayoutAccountMyOrders: () => import('..\\frontend\\components\\layout\\account-my-orders.vue' /* webpackChunkName: "components/frontend-layout-account-my-orders" */).then(c => c.default || c),
      LazyFrontendLayoutAccountOrderDetail: () => import('..\\frontend\\components\\layout\\account-order-detail.vue' /* webpackChunkName: "components/frontend-layout-account-order-detail" */).then(c => c.default || c),
      LazyFrontendLayoutAccountWishlist: () => import('..\\frontend\\components\\layout\\account-wishlist.vue' /* webpackChunkName: "components/frontend-layout-account-wishlist" */).then(c => c.default || c),
      LazyFrontendLayoutCatalog: () => import('..\\frontend\\components\\layout\\catalog.vue' /* webpackChunkName: "components/frontend-layout-catalog" */).then(c => c.default || c),
      LazyFrontendLayoutCheckout: () => import('..\\frontend\\components\\layout\\checkout.vue' /* webpackChunkName: "components/frontend-layout-checkout" */).then(c => c.default || c),
      LazyFrontendLayoutContactUs: () => import('..\\frontend\\components\\layout\\contact-us.vue' /* webpackChunkName: "components/frontend-layout-contact-us" */).then(c => c.default || c),
      LazyFrontendLayoutFooter: () => import('..\\frontend\\components\\layout\\footer.vue' /* webpackChunkName: "components/frontend-layout-footer" */).then(c => c.default || c),
      LazyFrontendLayoutHeader: () => import('..\\frontend\\components\\layout\\header.vue' /* webpackChunkName: "components/frontend-layout-header" */).then(c => c.default || c),
      LazyFrontendLayoutHome: () => import('..\\frontend\\components\\layout\\home.vue' /* webpackChunkName: "components/frontend-layout-home" */).then(c => c.default || c),
      LazyFrontendLayoutPayment: () => import('..\\frontend\\components\\layout\\payment.vue' /* webpackChunkName: "components/frontend-layout-payment" */).then(c => c.default || c),
      LazyFrontendLayoutProductDetail: () => import('..\\frontend\\components\\layout\\product-detail.vue' /* webpackChunkName: "components/frontend-layout-product-detail" */).then(c => c.default || c),
      LazyFrontendLayoutProducts: () => import('..\\frontend\\components\\layout\\products.vue' /* webpackChunkName: "components/frontend-layout-products" */).then(c => c.default || c),
      LazyFrontendLayoutServices: () => import('..\\frontend\\components\\layout\\services.vue' /* webpackChunkName: "components/frontend-layout-services" */).then(c => c.default || c),
      LazyFrontendLayoutShoppingCart: () => import('..\\frontend\\components\\layout\\shopping-cart.vue' /* webpackChunkName: "components/frontend-layout-shopping-cart" */).then(c => c.default || c),
      LazyFrontendLayoutSignUp: () => import('..\\frontend\\components\\layout\\sign-up.vue' /* webpackChunkName: "components/frontend-layout-sign-up" */).then(c => c.default || c),
      LazyFrontendLayoutSlider: () => import('..\\frontend\\components\\layout\\slider.vue' /* webpackChunkName: "components/frontend-layout-slider" */).then(c => c.default || c),
      LazyFrontendLayoutThankYou: () => import('..\\frontend\\components\\layout\\thank-you.vue' /* webpackChunkName: "components/frontend-layout-thank-you" */).then(c => c.default || c),
      LazyFrontendLayoutUserLogin: () => import('..\\frontend\\components\\layout\\user-login.vue' /* webpackChunkName: "components/frontend-layout-user-login" */).then(c => c.default || c),
      LazyFrontendCommonAddGoogleMap: () => import('..\\frontend\\components\\common\\add-google-map.vue' /* webpackChunkName: "components/frontend-common-add-google-map" */).then(c => c.default || c),
      LazyFrontendCommonAddedToCartDialog: () => import('..\\frontend\\components\\common\\added-to-cart-dialog.vue' /* webpackChunkName: "components/frontend-common-added-to-cart-dialog" */).then(c => c.default || c),
      LazyFrontendCommonAttributesAndSubitems: () => import('..\\frontend\\components\\common\\attributes-and-subitems.vue' /* webpackChunkName: "components/frontend-common-attributes-and-subitems" */).then(c => c.default || c),
      LazyFrontendCommonCatalogCard: () => import('..\\frontend\\components\\common\\catalog-card.vue' /* webpackChunkName: "components/frontend-common-catalog-card" */).then(c => c.default || c),
      LazyFrontendCommonCatalogList: () => import('..\\frontend\\components\\common\\catalog-list.vue' /* webpackChunkName: "components/frontend-common-catalog-list" */).then(c => c.default || c),
      LazyFrontendCommonChangePasswordCard: () => import('..\\frontend\\components\\common\\change-password-card.vue' /* webpackChunkName: "components/frontend-common-change-password-card" */).then(c => c.default || c),
      LazyFrontendCommonContactUsForm: () => import('..\\frontend\\components\\common\\contact-us-form.vue' /* webpackChunkName: "components/frontend-common-contact-us-form" */).then(c => c.default || c),
      LazyFrontendCommonCustomExpansionPanel: () => import('..\\frontend\\components\\common\\custom-expansion-panel.vue' /* webpackChunkName: "components/frontend-common-custom-expansion-panel" */).then(c => c.default || c),
      LazyFrontendCommonDashboardWelcomeCard: () => import('..\\frontend\\components\\common\\dashboard-welcome-card.vue' /* webpackChunkName: "components/frontend-common-dashboard-welcome-card" */).then(c => c.default || c),
      LazyFrontendCommonExpandingDatePicker: () => import('..\\frontend\\components\\common\\expanding-date-picker.vue' /* webpackChunkName: "components/frontend-common-expanding-date-picker" */).then(c => c.default || c),
      LazyFrontendCommonFileUploader: () => import('..\\frontend\\components\\common\\file-uploader.vue' /* webpackChunkName: "components/frontend-common-file-uploader" */).then(c => c.default || c),
      LazyFrontendCommonForgotPasswordCard: () => import('..\\frontend\\components\\common\\forgot-password-card.vue' /* webpackChunkName: "components/frontend-common-forgot-password-card" */).then(c => c.default || c),
      LazyFrontendCommonGallery: () => import('..\\frontend\\components\\common\\gallery.vue' /* webpackChunkName: "components/frontend-common-gallery" */).then(c => c.default || c),
      LazyFrontendCommonMyOrdersGrid: () => import('..\\frontend\\components\\common\\my-orders-grid.vue' /* webpackChunkName: "components/frontend-common-my-orders-grid" */).then(c => c.default || c),
      LazyFrontendCommonNumberOnlyInput: () => import('..\\frontend\\components\\common\\number-only-input.vue' /* webpackChunkName: "components/frontend-common-number-only-input" */).then(c => c.default || c),
      LazyFrontendCommonOrderDetailSubtotals: () => import('..\\frontend\\components\\common\\order-detail-subtotals.vue' /* webpackChunkName: "components/frontend-common-order-detail-subtotals" */).then(c => c.default || c),
      LazyFrontendCommonOrderInfoTable: () => import('..\\frontend\\components\\common\\order-info-table.vue' /* webpackChunkName: "components/frontend-common-order-info-table" */).then(c => c.default || c),
      LazyFrontendCommonPaymentCreditCard: () => import('..\\frontend\\components\\common\\payment-credit-card.vue' /* webpackChunkName: "components/frontend-common-payment-credit-card" */).then(c => c.default || c),
      LazyFrontendCommonProductCard: () => import('..\\frontend\\components\\common\\product-card.vue' /* webpackChunkName: "components/frontend-common-product-card" */).then(c => c.default || c),
      LazyFrontendCommonProductFilter: () => import('..\\frontend\\components\\common\\product-filter.vue' /* webpackChunkName: "components/frontend-common-product-filter" */).then(c => c.default || c),
      LazyFrontendCommonProductList: () => import('..\\frontend\\components\\common\\product-list.vue' /* webpackChunkName: "components/frontend-common-product-list" */).then(c => c.default || c),
      LazyFrontendCommonProductSwiper: () => import('..\\frontend\\components\\common\\product-swiper.vue' /* webpackChunkName: "components/frontend-common-product-swiper" */).then(c => c.default || c),
      LazyFrontendCommonShippingBillingEditForm: () => import('..\\frontend\\components\\common\\shipping-billing-edit-form.vue' /* webpackChunkName: "components/frontend-common-shipping-billing-edit-form" */).then(c => c.default || c),
      LazyFrontendCommonShippingBillingInfoCard: () => import('..\\frontend\\components\\common\\shipping-billing-info-card.vue' /* webpackChunkName: "components/frontend-common-shipping-billing-info-card" */).then(c => c.default || c),
      LazyFrontendCommonShoppingCartDeleteDialog: () => import('..\\frontend\\components\\common\\shopping-cart-delete-dialog.vue' /* webpackChunkName: "components/frontend-common-shopping-cart-delete-dialog" */).then(c => c.default || c),
      LazyFrontendCommonTableWithExtraHeader: () => import('..\\frontend\\components\\common\\table-with-extra-header.vue' /* webpackChunkName: "components/frontend-common-table-with-extra-header" */).then(c => c.default || c),
      LazyLogo: () => import('..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
      LazyVuetifyLogo: () => import('..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => c.default || c),
      LazyCommonChangePasswordCard: () => import('..\\components\\common\\change-password-card.vue' /* webpackChunkName: "components/common-change-password-card" */).then(c => c.default || c),
      LazyCommonColumnUserSettingDialog: () => import('..\\components\\common\\column-user-setting-dialog.vue' /* webpackChunkName: "components/common-column-user-setting-dialog" */).then(c => c.default || c),
      LazyCommonConfirm: () => import('..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => c.default || c),
      LazyCommonCustomNumberInputMinMax: () => import('..\\components\\common\\custom-number-input-min-max.vue' /* webpackChunkName: "components/common-custom-number-input-min-max" */).then(c => c.default || c),
      LazyCommonCustomNumberInput: () => import('..\\components\\common\\custom-number-input.vue' /* webpackChunkName: "components/common-custom-number-input" */).then(c => c.default || c),
      LazyCommonForgotPasswordDialog: () => import('..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => c.default || c),
      LazyCommonInfoBox: () => import('..\\components\\common\\info-box.vue' /* webpackChunkName: "components/common-info-box" */).then(c => c.default || c),
      LazyCommonJobDeclinePopup: () => import('..\\components\\common\\job-decline-popup.vue' /* webpackChunkName: "components/common-job-decline-popup" */).then(c => c.default || c),
      LazyCommonLeftMenu: () => import('..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => c.default || c),
      LazyCommonLoginCard: () => import('..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => c.default || c),
      LazyCommonNavigationBars: () => import('..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => c.default || c),
      LazyCommonNoteCardLayout: () => import('..\\components\\common\\note-card-layout.vue' /* webpackChunkName: "components/common-note-card-layout" */).then(c => c.default || c),
      LazyCommonNoteForm: () => import('..\\components\\common\\note-form.vue' /* webpackChunkName: "components/common-note-form" */).then(c => c.default || c),
      LazyCommonRegisterCard: () => import('..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => c.default || c),
      LazyCommonSwitchUser: () => import('..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => c.default || c),
      LazyCommonWebCam: () => import('..\\components\\common\\web-cam.vue' /* webpackChunkName: "components/common-web-cam" */).then(c => c.default || c),
      LazySharedAttributeFields: () => import('..\\components\\shared\\attribute-fields.vue' /* webpackChunkName: "components/shared-attribute-fields" */).then(c => c.default || c),
      LazySharedCardListViewToggleButton: () => import('..\\components\\shared\\card-list-view-toggle-button.vue' /* webpackChunkName: "components/shared-card-list-view-toggle-button" */).then(c => c.default || c),
      LazySharedCarouselTest: () => import('..\\components\\shared\\carousel-test.vue' /* webpackChunkName: "components/shared-carousel-test" */).then(c => c.default || c),
      LazySharedCloseBtn: () => import('..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => c.default || c),
      LazySharedCustomButton: () => import('..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => c.default || c),
      LazySharedCustomCombo: () => import('..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => c.default || c),
      LazySharedCustomSwitch: () => import('..\\components\\shared\\custom-switch.vue' /* webpackChunkName: "components/shared-custom-switch" */).then(c => c.default || c),
      LazySharedDarkButton: () => import('..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => c.default || c),
      LazySharedDataTableRowHandler: () => import('..\\components\\shared\\data-table-row-handler.vue' /* webpackChunkName: "components/shared-data-table-row-handler" */).then(c => c.default || c),
      LazySharedDecimalInput: () => import('..\\components\\shared\\decimal-input.vue' /* webpackChunkName: "components/shared-decimal-input" */).then(c => c.default || c),
      LazySharedDiscountFields: () => import('..\\components\\shared\\discount-fields.vue' /* webpackChunkName: "components/shared-discount-fields" */).then(c => c.default || c),
      LazySharedFileUploader: () => import('..\\components\\shared\\file-uploader.vue' /* webpackChunkName: "components/shared-file-uploader" */).then(c => c.default || c),
      LazySharedFilterButton: () => import('..\\components\\shared\\filter-button.vue' /* webpackChunkName: "components/shared-filter-button" */).then(c => c.default || c),
      LazySharedFilterCard: () => import('..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => c.default || c),
      LazySharedGallery: () => import('..\\components\\shared\\gallery.vue' /* webpackChunkName: "components/shared-gallery" */).then(c => c.default || c),
      LazySharedImageCombo: () => import('..\\components\\shared\\image-combo.vue' /* webpackChunkName: "components/shared-image-combo" */).then(c => c.default || c),
      LazySharedImageUploadCombo: () => import('..\\components\\shared\\image-upload-combo.vue' /* webpackChunkName: "components/shared-image-upload-combo" */).then(c => c.default || c),
      LazySharedImageUploaderField: () => import('..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => c.default || c),
      LazySharedImageUploader: () => import('..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => c.default || c),
      LazySharedImageViewer: () => import('..\\components\\shared\\image-viewer.vue' /* webpackChunkName: "components/shared-image-viewer" */).then(c => c.default || c),
      LazySharedInfoFields: () => import('..\\components\\shared\\info-fields.vue' /* webpackChunkName: "components/shared-info-fields" */).then(c => c.default || c),
      LazySharedLabeledControlFields: () => import('..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => c.default || c),
      LazySharedLabeledControlLayout: () => import('..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => c.default || c),
      LazySharedMenuDropdownButton: () => import('..\\components\\shared\\menu-dropdown-button.vue' /* webpackChunkName: "components/shared-menu-dropdown-button" */).then(c => c.default || c),
      LazySharedNameValueField: () => import('..\\components\\shared\\name-value-field.vue' /* webpackChunkName: "components/shared-name-value-field" */).then(c => c.default || c),
      LazySharedPrimarySubheader: () => import('..\\components\\shared\\primary-subheader.vue' /* webpackChunkName: "components/shared-primary-subheader" */).then(c => c.default || c),
      LazySharedPrimaryTitle: () => import('..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => c.default || c),
      LazySharedRadioCombo: () => import('..\\components\\shared\\radio-combo.vue' /* webpackChunkName: "components/shared-radio-combo" */).then(c => c.default || c),
      LazySharedSecondaryButton: () => import('..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => c.default || c),
      LazySharedSimpleInfoFields: () => import('..\\components\\shared\\simple-info-fields.vue' /* webpackChunkName: "components/shared-simple-info-fields" */).then(c => c.default || c),
      LazySharedSimpleNameValueField: () => import('..\\components\\shared\\simple-name-value-field.vue' /* webpackChunkName: "components/shared-simple-name-value-field" */).then(c => c.default || c),
      LazySharedThreeDFileDialog: () => import('..\\components\\shared\\three-d-file-dialog.vue' /* webpackChunkName: "components/shared-three-dfile-dialog" */).then(c => c.default || c),
      LazySharedTreeListRow: () => import('..\\components\\shared\\tree-list-row.vue' /* webpackChunkName: "components/shared-tree-list-row" */).then(c => c.default || c),
      LazyCommonMyProfileAddCardFields: () => import('..\\components\\common\\my-profile\\add-card-fields.vue' /* webpackChunkName: "components/common-my-profile-add-card-fields" */).then(c => c.default || c),
      LazyCommonMyProfileCreditCardInfoCard: () => import('..\\components\\common\\my-profile\\credit-card-info-card.vue' /* webpackChunkName: "components/common-my-profile-credit-card-info-card" */).then(c => c.default || c),
      LazyCommonMyProfileEditFormCard: () => import('..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => c.default || c),
      LazyCommonMyProfileGeneralInfoCard: () => import('..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => c.default || c),
      LazyCommonMyProfilePasswordChangeCard: () => import('..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => c.default || c)
  }

  const prefixes = ["frontend","nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
